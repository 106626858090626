export default {
  name: "TestSso",
  data() {
    return {
      timer: 60
    }
  },
  async created() {
    setInterval(() => {
      this.timer--
      if (this.timer <= 0) {
        window.close()
      }
    }, 1000)
  }
}